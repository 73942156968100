import { HttpClient } from '@angular/common/http';
import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ApiService } from 'src/app/core/services/api.service';

@Component({
    selector: 'app-login',
    templateUrl: './login.component.html',
})
export class LoginComponent implements OnInit {
    @Output() login: EventEmitter<void> = new EventEmitter<void>();
    public alert: boolean;
    public form: FormGroup;

    constructor(
        private _api: ApiService,
        private _fb: FormBuilder,
        private _http: HttpClient,
    ) {
    }

    ngOnInit(): void {
        localStorage.clear();
        this.form = this._fb.group({
            user: ['', Validators.required],
            pass: ['', Validators.required],
        });
    }

    onLogin(): void {
        this._http.post(`${this._api.api}/user/login`, this.form.getRawValue()).subscribe((r: any) => {
                localStorage.setItem('jwt', r.jwt);
                this.login.emit();
            }, () => this.showAlert(),
        );
    }

    private showAlert(): void {
        this.alert = true;
        setTimeout(() => {
            this.alert = false;
        }, 3000);
    }
}

import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, of, throwError } from 'rxjs';
import { catchError, mergeMap } from 'rxjs/operators';
import { LoaderService } from './loader.service';

@Injectable({
    providedIn: 'root',
})
export class ApiService {
    // public readonly api: string = 'http://localhost:3000';

    public readonly api: string = 'https://heidelberg-api.hellomovertis.com';
    public readonly apigeo = 'https://gateway-geocoder.hellomovertis.com';

    constructor(private _http: HttpClient, private _loader: LoaderService) {
    }

    post(url: string, obj: any): Observable<any> {
        this._loader.show();
        return this.setHeaders(obj, this.api + url).pipe(
            mergeMap((h: any) => this._http.post(h.url, h.payload, h.headers)),
            mergeMap((r: any) => {
                this._loader.hide();
                return of(r);
            }),
            catchError(e => {
                this._loader.hide();
                return throwError(e);
            }),
        );
    }

    postgeo(obj: any, entrega: string): Observable<any> {
        this._loader.show();
        return this.setHeadersGurtam(obj, 'https://gateway-geocoder.hellomovertis.com/geocoder/geocode').pipe(
            // return this.setHeadersGurtam(obj, 'http://localhost:3000/geocoder/geocode').pipe(
            mergeMap((h: any) => this._http.post(h.url, h.payload, h.headers)),
            mergeMap((r: any) => {
                this._loader.hide();
                console.log(r);
                return of({
                    lat: r && r[0] && r[0].y,
                    lng: r && r[0] && r[0].x,
                    entrega: entrega,
                });
            }),
            catchError(e => {
                this._loader.hide();
                console.log(e);
                return throwError(e);
            }),
        );
    }

    postgeoo(obj: any): Observable<any> {
        this._loader.show();
        return this.setHeadersGurtam(obj, 'https://gateway-geocoder.hellomovertis.com/geocoder/geocode').pipe(
            // return this.setHeadersGurtam(obj, 'http://localhost:3000/geocoder/geocode').pipe(
            mergeMap((h: any) => this._http.post(h.url, h.payload, h.headers)),
            mergeMap((r: any) => {
                this._loader.hide();
                console.log(r);
                return of({
                    lat: r && r[0] && r[0].y,
                    lng: r && r[0] && r[0].x,
                    // entrega: entrega,
                });
            }),
            catchError(e => {
                this._loader.hide();
                console.log(e);
                return throwError(e);
            }),
        );
    }

    postgeooo(obj: any): Observable<any> {
        this._loader.show();
        return this.setHeadersGurtam(obj, 'https://gateway-geocoder.hellomovertis.com/geocoder/geocode').pipe(
            // return this.setHeadersGurtam(obj, 'http://localhost:3000/geocoder/geocode').pipe(
            mergeMap((h: any) => this._http.post(h.url, h.payload, h.headers)),
            mergeMap((r: any) => {
                this._loader.hide();
                console.log(r);
                return of({
                    lat: r && r[0] && r[0].y,
                    lng: r && r[0] && r[0].x,
                    // entrega: entrega,
                });
            }),
            catchError(e => {
                this._loader.hide();
                console.log(e);
                return throwError(e);
            }),
        );
    }

    patch(url: string, obj: any): Observable<any> {
        this._loader.show();
        return this.setHeaders(obj, this.api + url).pipe(
            mergeMap((h: any) => this._http.patch(h.url, h.payload, h.headers)),
            mergeMap((r: any) => {
                this._loader.hide();
                return of(r);
            }),
            catchError(e => {
                this._loader.hide();
                return throwError(e);
            }),
        );
    }

    get(url: string): Observable<any> {
        this._loader.show();
        return this.setHeaders(null, this.api + url).pipe(
            mergeMap((h: any) => this._http.get(h.url, h.headers)),
            mergeMap((r: any) => {
                this._loader.hide();
                return of(r);
            }),
            catchError(e => {
                this._loader.hide();
                return throwError(e);
            }),
        );
    }

    put(url: string, obj: any): Observable<any> {
        this._loader.show();
        return this.setHeaders(obj, this.api + url).pipe(
            mergeMap((h: any) => this._http.put(h.url, h.payload, h.headers)),
            mergeMap((r: any) => {
                this._loader.hide();
                return of(r);
            }),
            catchError(e => {
                this._loader.hide();
                return throwError(e);
            }),
        );
    }

    delete(url: string): Observable<any> {
        this._loader.show();
        return this.setHeaders(null, this.api + url).pipe(
            mergeMap((h: any) => this._http.delete(h.url, h.headers)),
            mergeMap((r: any) => {
                this._loader.hide();
                return of(r);
            }),
            catchError(e => {
                this._loader.hide();
                return throwError(e);
            }),
        );
    }

    private setHeaders(data: any, url: string): Observable<any> {
        const jwt: any = localStorage.getItem('jwt');
        if (jwt) {
            const httpOptions = {
                headers: new HttpHeaders({
                    'Content-Type': 'application/json',
                    'authorization': jwt || 'no-jwt',
                }),
            };
            return of({ headers: httpOptions, payload: data, url: url });
        }
    }

    private setHeadersGurtam(data: any, url: string): Observable<any> {
        const jwt: any = localStorage.getItem('jwt2');
        if (jwt) {
            const httpOptions = {
                headers: new HttpHeaders({
                    'Content-Type': 'application/json',
                    'jwt': jwt || 'no-jwt',
                }),
            };
            return of({ headers: httpOptions, payload: data, url: url });
        }
    }
}

import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { ApiService } from './core/services/api.service';

@Component({
    selector: 'app-root',
    templateUrl: './app.component.html',
})
export class AppComponent implements OnInit {
    public menus: { name: string; key: string }[] = [];
    public logged: boolean = false;

    constructor(
        private _router: Router,
        private _api: ApiService,
    ) {
    }

    ngOnInit(): void {
        this.onLogin();
    }

    onLogout(): void {
        this.menus = [];
        this.logged = false;
        localStorage.clear();
    }

    onLogin(): void {
        this.logged = !!localStorage.getItem('jwt');
        if (this.logged) {
            this._api.get('/user/user/role').subscribe(r => {
                localStorage.setItem('permisos', r.permisos);
                this.buildMenus(r.permisos);
                switch (r.permisos) {
                    case 'admin':
                        this._router.navigate(['dashboard']);
                        break;
                    case 'provider':
                        this._router.navigate(['dashboard']);
                        break;
                    case 'comercial':
                        this._router.navigate(['map']);
                        break;
                    case 'client':
                        this._router.navigate(['map']);
                }
            });
        }
    }

    onMenu(key: string): void {
        this._router.navigate([key]);
    }

    private buildMenus(permisos): void {
        this.menus = [];
        const shared: { name: string; key: string }[] = [
            { name: 'Mapa', key: 'map' },
            { name: 'Informes', key: 'reports' },
        ];

        switch (permisos) {
            case 'admin':
                this.menus.push(
                    { name: 'Dashboard', key: 'dashboard' },
                    { name: 'Incidencias', key: 'incidence' },
                    { name: 'Ventas', key: 'task' },
                    { name: 'Compras', key: 'buy' },
                    ...shared,
                    { name: 'Transportistas', key: 'provider' },
                    { name: 'Clientes', key: 'client' },
                    { name: 'Comerciales', key: 'comercial' },
                    { name: 'Operadores', key: 'operador' },
                    { name: 'Administradores', key: 'admin' },
                );
                break;
            case 'provider':
                this.menus.push(
                    { name: 'Dashboard', key: 'dashboard' },
                    { name: 'Incidencias', key: 'incidence' },
                    { name: 'Ventas', key: 'task' },
                    { name: 'Compras', key: 'buy' },
                    ...shared,
                    { name: 'Vehículos', key: 'vehicle' },
                    { name: 'Conductores', key: 'driver' },
                );
                break;
            case 'comercial':
                this.menus.push(
                    ...shared,
                    { name: 'Entregas', key: 'task' },
                );
                break;
            case 'client':
                this.menus.push(
                    ...shared,
                    { name: 'Entregas', key: 'task' },
                );
                break;
        }
    }
}

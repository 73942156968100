import { Injectable } from '@angular/core';
import { Observable, Subject } from 'rxjs';

@Injectable({ providedIn: 'root' })
export class LoaderService {
    private $obs: Subject<boolean> = new Subject<boolean>();
    private persist: boolean = false;

    obs(): Observable<boolean> {
        return this.$obs.asObservable();
    }

    show(persist: boolean = false): void {
        if (persist) {
            this.persist = true;
        }
        this.$obs.next(true);
    }

    hide(persist: boolean = false): void {
        if (!this.persist) {
            this.$obs.next(false);
        } else if (this.persist && persist) {
            this.persist = false;
            this.$obs.next(false);
        }
    }
}

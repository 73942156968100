import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AuthGuard } from './core/guards/auth.guard';

const routes: Routes = [
    { path: 'admin', loadChildren: () => import('./admins/admins.module').then(m => m.AdminsModule), canLoad: [AuthGuard] },
    { path: 'client', loadChildren: () => import('./clients/clients.module').then(m => m.ClientsModule), canLoad: [AuthGuard] },
    { path: 'operador', loadChildren: () => import('./operador/operador.module').then(m => m.OperadorModule), canLoad: [AuthGuard] },
    { path: 'comercial', loadChildren: () => import('./comercials/comercials.module').then(m => m.ComercialsModule), canLoad: [AuthGuard] },
    { path: 'dashboard', loadChildren: () => import('./dashboard/dashboard.module').then(m => m.DashboardModule), canLoad: [AuthGuard] },
    { path: 'incidence', loadChildren: () => import('./incidences/incidences.module').then(m => m.IncidencesModule), canLoad: [AuthGuard] },
    { path: 'map', loadChildren: () => import('./map/map.module').then(m => m.MapModule), canLoad: [AuthGuard] },
    { path: 'provider', loadChildren: () => import('./providers/providers.module').then(m => m.ProvidersModule), canLoad: [AuthGuard] },
    { path: 'task', loadChildren: () => import('./tasks/tasks.module').then(m => m.TasksModule), canLoad: [AuthGuard] },
    { path: 'vehicle', loadChildren: () => import('./vehicle/vehicle.module').then(m => m.VehicleModule), canLoad: [AuthGuard] },
    { path: 'driver', loadChildren: () => import('./driver/driver.module').then(m => m.DriverModule), canLoad: [AuthGuard] },
    { path: 'forms', loadChildren: () => import('./forms/forms.module').then(m => m.FormsPageModule), canLoad: [AuthGuard] },
    { path: 'buy', loadChildren: () => import('./buys/buys.module').then(m => m.BuysModule), canLoad: [AuthGuard] },
    { path: 'reports', loadChildren: () => import('./reports/reports.module').then(m => m.ReportsModule), canLoad: [AuthGuard] },

    { path: '**', redirectTo: 'home', pathMatch: 'full' },
    { path: '', redirectTo: 'home', pathMatch: 'full' },
];

@NgModule({
    imports: [RouterModule.forRoot(routes, { useHash: true })],
    exports: [RouterModule],
})
export class AppRouting {
}
